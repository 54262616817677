<template>
    <div class="main money">
        <ul class="tabs">
            <li>市级项目资金</li>
        </ul>
        <div class="container">
            <div class="filter">
                <!--<addFund :edit="false"></addFund>-->
                <div class="item">
                    <div class="left">拨款年度</div>
                    <el-date-picker size="medium" v-model="year" type="year" :clearable="false" value-format="yyyy"
                                    placeholder="请选择拨款年度" @change="search"
                                    style="margin-right:10px;width: 200px;"></el-date-picker>
                </div>
                <div class="item">
                    <div class="left">所属地区</div>
                    <el-select size="medium" v-model="area" popper-class="global_select_popper" @change="search"
                               :disabled="fund_project_power.area>0">
                        <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <div class="left">建设性质</div>
                    <el-select size="medium" v-model="build_quality" popper-class="global_select_popper"
                               @change="search">
                        <el-option v-for="item in buildQuality" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <el-button size="small" type="primary" class="button_108px" @click="projectMoneyOut">项目资金导出</el-button>
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column prop="project_number" label="序号" align="center"></el-table-column>
                    <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                    <el-table-column label="总投资金额(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.all_invest_num">{{
                                    Number(scope.row.all_invest_num) / 10000
                                }}万元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="第一次拨款" align="center">
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.fund_info&&scope.row.fund_info.money1!==''&&scope.row.fund_info.money1!==undefined&&scope.row.fund_info.money1!==null">
                                {{ scope.row.fund_info.money1 }}万元
                                <!--暂时隐藏是否已确认-->
                                <!--<span v-if="scope.row.fund_info.money1_status==2&&!scope.row.fund_info.month1">( 已确认 )</span>-->
                                <!--<span v-else-if="scope.row.fund_info.month1">( {{new Date(scope.row.fund_info.month1).getFullYear()}}年{{new Date(scope.row.fund_info.month1).getMonth()+1}}月已确认 )</span>-->
                                <!--<span v-else>( 未确认 )</span>-->
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="第二次拨款" align="center">
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.fund_info&&scope.row.fund_info.money2!==''&&scope.row.fund_info.money2!==undefined&&scope.row.fund_info.money2!==null">
                                {{ scope.row.fund_info.money2 }}万元
                                <!--<span v-if="scope.row.fund_info.money2_status==2&&!scope.row.fund_info.month2">( 已确认 )</span>-->
                                <!--<span v-else-if="scope.row.fund_info.month2">( {{new Date(scope.row.fund_info.month2).getFullYear()}}年{{new Date(scope.row.fund_info.month2).getMonth()+1}}月已确认 )</span>-->
                                <!--<span v-else>( 未确认 )</span>-->
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="拨款进度" align="center" width="195">
                        <template slot-scope="scope">
                            <div class="progress">
                                <el-progress :stroke-width="12" :percentage="scope.row.progress"
                                             :color="customColorMethod"></el-progress>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="155" v-if="fund_project_power.power.edit">
                        <template slot-scope="scope">
                            <addFund :edit="false" :prop_fund="scope.row" :year="year"
                                     v-if="(scope.row.fund_info.money1===''||scope.row.fund_info.money1===undefined||scope.row.fund_info.money1===null)&&(scope.row.fund_info.money2===''||scope.row.fund_info.money2===undefined||scope.row.fund_info.money2===null)"
                                     @refresh="fundLists"></addFund>
                            <div v-else>
                                <addFund :edit="true" :prop_fund="scope.row" :year="year"
                                         @refresh="fundLists"></addFund>
                                <el-button type="danger" class="button_58px" size="mini" style="margin-left: 10px;"
                                           @click="deleteFund(scope.row.fund_info.id)"
                                           :disabled="scope.row.fund_info.money1_status==2||scope.row.fund_info.money2_status==2">
                                    删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import customColor from '@/mixins/customColor.js';
import addFund from '@c/money/add_fund.vue';
import globalYear from '@/mixins/global_year';

export default {
    mixins: [globalYear, customColor],
    components: {addFund},
    data() {
        return {
            tableData: [],
            page: 1,
            pagesize: 15,
            total: 0,
            loading: false,
            // year: new Date().getFullYear().toString(),
            areaList: [
                {value: 0, label: '全部(默认)'},
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ],
            area: 0,
            build_quality: 0,
            buildQuality: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '新建'},
                {value: 2, label: '续建'},
                {value: 3, label: '其它'},
                {value: 4, label: '完工未竣工'},
                {value: 5, label: '跨年'},
            ],
        };
    },
    computed: {
        fund_project_power() {
            return this.$store.state.user.current_user.fund_project_power;
        },
        current_userid() {
            return this.$store.state.user.current_user.userid;
        }
    },
    created() {
        if (this.fund_project_power.area > 0) {
            this.area = this.fund_project_power.area;
        }
        this.fundLists();
    },
    methods: {
        search() {
            this.page = 1;
            this.fundLists();
        },
        fundLists() {
            this.loading = true;
            this.tableData = [];
            let param = {page: this.page, pagesize: this.pagesize};
            if (this.year) param.year = this.year;
            if (this.area) param.area = this.area;
            if (this.build_quality) param.build_quality = this.build_quality;
            // if (!this.fund_project_power.area) {
            //     param.fuze_users = this.current_userid;
            // }
            // if (this.fund_project_power.area === 1) {
            //     param.fuze_users = this.current_userid;
            // }
            api.project.projectList(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    let list = [];
                    if (res.data.lists) {
                        list = res.data.lists;
                    }
                    if (list.length > 0 && res.data.fund_info) {
                        list.forEach(li => {
                            li.fund_info = {};
                            if (li.year_invest_num) li.year_invest_num = JSON.parse(li.year_invest_num);
                            else li.year_invest_num = {};
                            res.data.fund_info.forEach(fund => {
                                if (fund.project_id === li.project_id) {
                                    //对应了拨款年度
                                    // if (fund.year && fund.year.slice(0, 4) == this.year) {
                                    li.fund_info = fund;
                                    // }
                                }
                            });
                        });
                        list.forEach(li => {
                            li.year_invest_num_total = 0;
                            for (let year in li.year_invest_num) {
                                if (year == this.year) {
                                    li.year_invest_num_total = Number(li.year_invest_num[year]) / 10000;
                                }
                            }
                            if (li.fund_info.money1 !== null && li.fund_info.money1 !== undefined && li.fund_info.money1 !== '') {
                                li.fund_info.money1 = Number(li.fund_info.money1) / 10000;
                            }
                            // else{
                            //     li.fund_info.money1=0
                            // }
                            if (li.fund_info.money2 !== null && li.fund_info.money2 !== undefined && li.fund_info.money2 !== '') {
                                li.fund_info.money2 = Number(li.fund_info.money2) / 10000;
                            }
                            // else{
                            //     li.fund_info.money2=0
                            // }

                            if (li.all_invest_num && li.fund_info.money1 && (li.fund_info.money2 === null || li.fund_info.money2 === undefined || li.fund_info.money2 === '')) {//只有第一次拨款
                                li.progress = Math.ceil(Number(li.fund_info.money1) / (Number(li.all_invest_num) / 10000) * 100);
                            } else if (li.all_invest_num && li.fund_info.money1 && li.fund_info.money2) {//两次拨款都有
                                li.progress = Math.ceil((Number(li.fund_info.money1) + Number(li.fund_info.money2)) / (Number(li.all_invest_num) / 10000) * 100);
                            } else {
                                li.progress = 0;
                            }
                            // if (li.year_invest_num_total && li.fund_info.money1 && li.fund_info.money2) {
                            //     li.progress = Math.ceil((Number(li.fund_info.money1) + Number(li.fund_info.money2)) / Number(li.year_invest_num_total) * 100);
                            // } else {
                            //     li.progress = 0;
                            // }

                            // if (li.progress > 100) {
                            //     li.progress = 100;
                            // }
                        });
                    }
                    this.tableData = list;
                    // console.log(this.tableData, 'tabledata')
                }
                this.loading = false;
            });
        },
        pageChange(page) {
            this.page = page;
            this.fundLists();
        },
        deleteFund(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定删除该资金到账记录吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.money.deleteFund({id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '资金到账记录删除成功',
                                    type: 'success'
                                });
                                this.fundLists();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        //项目资金导出
        projectMoneyOut() {
            let param = {
                page: 1,
                pagesize: 9999,
            };
            let _url = window.location.href;
            let __url = _url.split("#")[0];
            let url = __url + "index.php/cqh/Project/projectMoneyOut/?token=" + this.$store.state.user.token + `&page=${param.page}&pagesize=${param.pagesize}`;
            if (this.year) {
                param.year = this.year;
                url = url + "&year=" + param.year;
            }
            if (this.area) {
                param.area = this.area;
                url = url + "&area=" + param.area;
            }
            if (this.build_quality) {
                param.build_quality = this.build_quality;
                url = url + "&build_quality=" + param.build_quality;
            }
            let self = this;
            api.money.projectMoneyOut(param).then(res => {
                dd.biz.util.openLink({
                    url, //要打开链接的地址
                    onSuccess: function (result) {
                        console.log('打开导出地址成功');
                        self.show = false;
                    },
                    onFail: function (err) {
                        console.log('打开导出地址失败', err);
                        self.show = false;
                    }
                });
            });
        }
    }
};
</script>

<style scoped lang="less">
.money {
    .filter {
        height: 50px;
        display: flex;
        align-items: center;

        .item {
            /*margin-bottom:10px;*/
            display: flex;
            align-items: center;
            flex: 0 0 215px;
            margin-right: 20px;

            &.btn {
                flex: 0 0 88px;
            }

            .left {
                flex: 0 0 65px;
                padding: 0 !important;
            }

            /deep/ .el-date-editor.el-input {
                width: 100%;
            }
        }
    }
}
</style>