import { render, staticRenderFns } from "./add_fund.vue?vue&type=template&id=78f58ab0&scoped=true"
import script from "./add_fund.vue?vue&type=script&lang=js"
export * from "./add_fund.vue?vue&type=script&lang=js"
import style0 from "./add_fund.vue?vue&type=style&index=0&id=78f58ab0&prod&scoped=true&lang=less"
import style1 from "./add_fund.vue?vue&type=style&index=1&id=78f58ab0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_prettier@1.19.1_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f58ab0",
  null
  
)

export default component.exports