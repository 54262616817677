<template>
    <div class="add_fund">
        <el-button size="mini" type="primary" class="button_98px" v-if="!edit" @click="openAdd">添加项目资金</el-button>
        <el-button type="primary" class="button_58px" size="mini" v-if="edit" @click="openEdit" :disabled="prop_fund.fund_info.money1_status==2&&prop_fund.fund_info.money2_status==2">编辑</el-button>
        <el-dialog :title="!edit?'添加项目资金':'编辑项目资金'" :visible.sync="show" width="500px" :close-on-click-modal="false" append-to-body @closed="$refs.ruleForm.resetFields()">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="125px" class="parent_form add_fund_form">
                <div class="row">
                    <div class="item long">
                        <el-form-item label="第一次拨款" prop="money1">
                            <el-input-number v-model="ruleForm.money1" :disabled="prop_fund.fund_info.money1_status==2" :step="1" :controls="false" placeholder="填写金额"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="第二次拨款" prop="money2">
                            <el-input-number v-model="ruleForm.money2" :disabled="prop_fund.fund_info.money2_status==2" :step="1" :controls="false" placeholder="填写金额"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                </div>
                <div class="button">
                    <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    export default {
        props:['edit','prop_fund','year'],
        data(){
            return{
                show:false,projectList:[],formData: {page: 1, pagesize:20,total_page:0},selectPro:{},//资金拨款项目
                ruleForm:{
                    project_id:'',year:'',money1:undefined,money2:undefined
                },
                rules:{
                    project_id: [{ required: true, message: '请选择项目', trigger: 'change' }],
                    money1:[{ required: true, message: '请输入第一次拨款金额', trigger: 'blur'}],
                }
            }
        },
        methods:{
            openAdd(){
                this.ruleForm={project_id:'',year:'',money1:undefined,money2:undefined};
                this.show=true;
            },
            openEdit(){
                this.ruleForm=JSON.parse(JSON.stringify(this.prop_fund));
                this.ruleForm.money1=Number(this.prop_fund.fund_info.money1);
                if(!this.prop_fund.fund_info.money2){
                    this.ruleForm.money2=undefined;
                }else{
                    this.ruleForm.money2=Number(this.prop_fund.fund_info.money2)
                }
                this.show=true;
            },
            submitForm(){
                console.log(this.prop_fund.all_invest_num,'prop_fund')
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if(this.ruleForm.money1===undefined||this.ruleForm.money1===null||this.ruleForm.money1===''){
                            this.$message.warning('第一次拨款金额不允许删除！')
                            return;
                        }
                        let param={project_id:this.prop_fund.project_id, year:this.year, money1:'', money2:''};
                        if(this.edit) param.id=this.prop_fund.fund_info.id;
                        param.money1=Number(this.ruleForm.money1)*10000;
                        if(this.ruleForm.money2!==null&&this.ruleForm.money2!==undefined&&this.ruleForm.money2!=='') param.money2=Number(this.ruleForm.money2)*10000;

                        const h = this.$createElement;
                        let message=[];
                        // if(!param.money2){
                        //     if(param.money1>this.prop_fund.year_invest_num[Number(this.year)]){
                        //         message.push(h('p', null, `第一次拨款金额 ${param.money1/10000}万元 大于 ${this.year}年度投资金额 ${Number(this.prop_fund.year_invest_num[Number(this.year)])/10000}万元`))
                        //     }
                        // }else{
                        //     if((param.money1+param.money2)>this.prop_fund.year_invest_num[Number(this.year)]){
                        //         message.push(h('p', null, `拨款总金额 ${(param.money1+param.money2)/10000}万元 大于 ${this.year}年度投资金额 ${Number(this.prop_fund.year_invest_num[Number(this.year)])/10000}万元`))
                        //     }
                        // }

                        if(!param.money2){
                            if(param.money1>this.prop_fund.all_invest_num){
                                message.push(h('p', null, `第一次拨款金额 ${param.money1/10000}万元 大于 总投资金额 ${Number(this.prop_fund.year_invest_num[Number(this.year)])/10000}万元`))
                            }
                        }else{
                            if((param.money1+param.money2)>this.prop_fund.all_invest_num){
                                message.push(h('p', null, `拨款总金额 ${(param.money1+param.money2)/10000}万元 大于 总投资金额 ${Number(this.prop_fund.year_invest_num[Number(this.year)])/10000}万元`))
                            }
                        }
                        if(message.length>0){
                            this.$msgbox({
                                title: '添加项目资金待确认',
                                message: h('p', null, message),
                                showCancelButton: true,
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                beforeClose: (action, instance, done) => {
                                    if (action === 'confirm') {
                                        this.addFundApi(param);
                                        done();
                                    } else {
                                        done();
                                    }
                                }
                            }).catch(() => {

                            });
                        }else{
                            this.addFundApi(param);
                        }
                    }
                });
            },
            addFundApi(param){
                api.money.addFund(param).then(res=>{
                    if(res.data.state==1){
                        this.$message({
                            message: `${this.edit?'编辑':'添加'}项目资金成功`,
                            type: 'success'
                        });
                        this.show=false;
                        this.$emit('refresh');
                    }
                })
            },
            // getList(formData) {
            //     if(formData.page==1) this.projectList = [];
            //     api.money.listNotFund({page:formData.page,pagesize:formData.pagesize}).then(res=>{
            //         if(res.data.state==1){
            //             if(res.data&&res.data.project){
            //                 let project=res.data.project;
            //                 if(project.count) this.formData.total_page=Math.ceil(project.count / this.formData.pagesize);
            //                 else this.formData.total_page=0;
            //                 let list=[]
            //                 if(project.lists){
            //                     list=project.lists;
            //                 }
            //                 if(list.length>0&&res.data.fund){
            //                     list.forEach(li=>{
            //                         li.fund_year=[];
            //                         if(li.year_invest_num) li.year_invest_num=JSON.parse(li.year_invest_num);
            //                         else li.year_invest_num={};
            //                         res.data.fund.forEach(fund=>{
            //                             if(fund.project_id===li.project_id&&fund.year){
            //                                 li.fund_year.push(Number(fund.year.slice(0,4)))
            //                             }
            //                         })
            //                     })
            //                     list.forEach(li=>{
            //                         li.years=[];
            //                         for(let year in li.year_invest_num){
            //                             if(year&&li.fund_year.indexOf(Number(year))===-1){
            //                                 li.years.push(Number(year))
            //                             }
            //                         }
            //                     })
            //                 }
            //                 this.projectList = [...this.projectList, ...list];
            //             }
            //         }
            //     })
            // },
            /*changePro(pro){
                this.ruleForm.project_id=pro.project_id;
                if(pro.years.length>0){
                    this.ruleForm.year=pro.years[0];
                }
            },
            loadMore() {
                if(this.formData.total_page>this.formData.page){
                    this.formData.page++;
                    this.getList(this.formData);
                }
            },*/
        },
        /*directives: {
            'el-select-loadmore': {
                bind(el, binding) {
                    // 获取element-ui定义好的scroll盒子
                    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
                    SELECTWRAP_DOM.addEventListener('scroll', function () {
                        /!**
                         * scrollHeight 获取元素内容高度(只读)
                         * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
                         * clientHeight 读取元素的可见高度(只读)
                         * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
                         * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
                         *!/
                        const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
                        if (condition) {
                            binding.value();
                        }
                    });
                }
            }
        },*/
    }
</script>

<style scoped lang="less">
    div.add_fund{
        display:inline-block;
    }
</style>
<style lang="less">
    .add_fund_form.parent_form{
        .radio_group{
            width: 100%;
            padding-left: 40px;
            flex-wrap: wrap;
            .radio{
                margin-bottom: 20px;
            }
        }
        .button{
            border:none!important;
        }
    }
</style>