import {mapState,mapMutations} from 'vuex';
export default {
    data(){
        return{
            year:'',
        }
    },
    computed:{
        ...mapState('user',[
            'search_year'
        ])
    },
    created(){
        this.year = this.search_year;
    },
    methods:{
        ...mapMutations('user', [
            'SETYEAR'
        ]),
    },
    watch:{
        'year':{
            handler(year){
                if(year){
                    this.SETYEAR && this.SETYEAR(this.year)
                }

            },
            immediate:true
        }
    }
}