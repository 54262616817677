export default {
    methods:{
        customColorMethod(percentage) {
            if (percentage < 33) {
                return '#f85451';
            } else if (percentage < 66) {
                return '#fb9c2c';
            }else {
                return '#34c30d';
            }
        },
        apiReturnColor(color){
            if(color==1){
                return '#34c30d';
            }else if(color==2){
                return '#fb9c2c';
            }else if(color==3){
                return '#f85451';
            }else{
                return '#f85451';
            }
            // switch (Number(color)) {
            //     case 1:
            //         return '#34c30d';
            //     case 2:
            //         return '#fb9c2c';
            //     case 3:
            //         return '#f85451';
            // }
        }
    }
}